import { combineReducers, configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";

import rootSaga from "./sagas";

import { authActions, authReducer, countryActions, countryReducer, cryptoActions, cryptoReducer, currencyActions, currencyReducer, feeActions, feeReducer, orderActions, orderReducer, quoteActions, quoteReducer } from "./slices";

const reducer = combineReducers({
  auth: authReducer,
  order: orderReducer,
  crypto: cryptoReducer,
  currency: currencyReducer,
  fee: feeReducer,
  quote: quoteReducer,
  country: countryReducer,
});

const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
  preloadedState: {},
  reducer,
  middleware: (getDefaultMiddleware: any) =>
    getDefaultMiddleware({ serializableCheck: false, thunk: false }).concat(
      sagaMiddleware
    ),
});


export const AppActions = {
  auth: authActions,
  order: orderActions,
  crypto: cryptoActions,
  currency: currencyActions,
  fee: feeActions,
  quote: quoteActions,
  country: countryActions,
};

sagaMiddleware.run(rootSaga);
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

import { useContext, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import countryList from "react-select-country-list";
import {
  Frames,
  CardNumber,
  ExpiryDate,
  Cvv,
} from "components/checkout-frames";
import {
  postcodeValidator,
  postcodeValidatorExistsForCountry,
} from "postcode-validator";

import Input from "components/common/InputForCheckout";
import { DropDownForCheckout } from "components/common";
import { AppActions, RootState } from "store";

import { PATHS } from "consts";
import { PlatformContext } from "contexts";
import { ISsoPayload } from "types";
import { ReactComponent as CancelInCircleSvg } from "assets/icons/cancel-in-circle.svg";
import PendingIcon from "assets/icons/PendingIcon";

export const CardInfoPage: React.FC<{ isCardValid: boolean }> = ({
  isCardValid,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { ssoPayload, saveSsoPayload } = useContext(PlatformContext);

  const [isFormReady, setIsFormReady] = useState<boolean>(false);
  const [isInprogress, setIsInProgress] = useState<boolean>(false);
  const [currency, setCurrency] = useState<string>("USD");
  const [cardTokenizedVal, setCardTokenizedVal] = useState<any>({});
  const [errors, setErrors] = useState<any>({
    name: "",
    billingAddress: "",
    zipCode: "",
    city: "",
  });
  const options = useMemo(() => countryList().getData(), []);

  const {
    error: errorMessage,
    order,
    loading: isLoading,
    amount,
    currency: orderCurrency,
    orderUuid: currentOrderUuid,
    billingAddress,
  } = useSelector((state: RootState) => state.order);

  const { currencies } = useSelector((state: RootState) => state.currency);

  const [cardholder, setCardholder] = useState({
    name: "",
    billingAddress: ssoPayload?.address_line1
      ? ssoPayload?.address_line1
      : billingAddress.address_line1,
    country: options.filter(
      (op) => op.value === (ssoPayload?.country ?? "HK")
    )[0],
    zipCode: ssoPayload?.zip ? ssoPayload?.zip : billingAddress.zip,
    city: ssoPayload?.city ? ssoPayload?.city : billingAddress.city,
  });

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const paramValue = searchParams.get("cko-session-id");
    if (paramValue)
      dispatch(AppActions.order.getPaymentDetail({ sessionId: paramValue }));

    const ssoOrderIdFromUrl = location.pathname.replace(
      `${PATHS.CARD_INFO}/`,
      ""
    );
    const ssoOrderId = ssoPayload?.sso_order_id;
    if (
      !ssoOrderIdFromUrl.includes(PATHS.CARD_INFO) &&
      ssoOrderIdFromUrl.length &&
      !ssoOrderId
    ) {
      saveSsoPayload({ sso_order_id: ssoOrderIdFromUrl } as ISsoPayload);
    }
  }, [location.search]);

  useEffect(() => {
    if (currencies.length && order) {
      const currentCurrency = currencies.filter(
        (currency) => +currency.id === +order?.currencyId
      )[0];
      setCurrency(currentCurrency.code);
    }
  }, [currencies, order]);

  useEffect(() => {
    if (
      cardholder.country &&
      postcodeValidatorExistsForCountry(cardholder.country.value) &&
      cardholder.zipCode
    ) {
      if (postcodeValidator(cardholder.zipCode, cardholder.country.value)) {
        setErrors({ ...errors, zipCode: "" });
      } else {
        setErrors({ ...errors, zipCode: "Invalid zip code" });
      }
    }
  }, [cardholder.zipCode, cardholder.country]);

  const onChangeCardHolderInfo = (event: any) => {
    setCardholder({ ...cardholder, [event.target.name]: event.target.value });
    if (event.target.name !== "zipCode") setErrors({ zipCode: errors.zipCode });
  };

  const onCancelContinue = () => {
    dispatch(AppActions.order.initOrderStatus({}));
    navigate("/" + PATHS.HOME);
    window.parent.postMessage(
      {
        type: "order_cancelled",
      },
      "*"
    );
  };

  useEffect(() => {
    const ssoOrderId = location.pathname.replace(`${PATHS.CARD_INFO}/`, "");
    cardTokenizedVal?.token &&
      dispatch(
        AppActions.order.createPaymentCheckout({
          type: "sso_login",
          orderUuid: order?.orderUuid ? order?.orderUuid : currentOrderUuid,
          token: cardTokenizedVal.token,
          amount: order?.fiatAmount ? order?.fiatAmount : amount,
          cardNumber: `${cardTokenizedVal.bin}******${cardTokenizedVal.last4}`,
          cardType: `${cardTokenizedVal.card_type}`,
          cardHolder: cardholder.name,
          cardScheme: `${cardTokenizedVal.scheme}`,
          currency: order?.currencyId ? currency : orderCurrency,
          issuerCountry: cardTokenizedVal.issuer_country,
          billingAddress: {
            zip: cardholder.zipCode,
            country: cardholder.country.value,
            address_line1: cardholder.billingAddress,
            city: ssoPayload?.city ? ssoPayload.city : cardholder.city,
          },
          successUrl: `${process.env.REACT_APP_SUCCESS_URL}/${
            ssoPayload?.sso_order_id ? ssoPayload?.sso_order_id : ssoOrderId
          }`,
          failureUrl: `${process.env.REACT_APP_FAILURE_URL}/${
            ssoPayload?.sso_order_id ? ssoPayload?.sso_order_id : ssoOrderId
          }`,
          next: (redirectUrl: string) => {
            setIsInProgress(false);
            if (redirectUrl) window.location.replace(redirectUrl);
            else navigate(PATHS.PAYMENT_SUCCESS);
          },
          nextFailure: (redirectUrl: string) => {
            setIsInProgress(false);
            if (redirectUrl) window.location.replace(redirectUrl);
            else navigate(PATHS.CARD_INFO);
          },
        })
      );
  }, [currentOrderUuid, amount, order, cardTokenizedVal, dispatch, navigate]);

  const validateFields = () => {
    if (cardholder.name && cardholder.zipCode && cardholder.billingAddress) {
      return true;
    } else {
      setErrors({
        zipCode: cardholder.zipCode.length === 0 && "Zip Code is required",
        name: cardholder.name.length === 0 && "Card holder name is required",
        billingAddress:
          cardholder.billingAddress.length === 0 &&
          "Billing address is required",
        city: cardholder.city.length === 0 && "City is required",
      });
      return false;
    }
  };

  return (
    <>
      {(isLoading || !isFormReady || isInprogress) && (
        <div className="w-[100%] h-[100%] flex items-center justify-center absolute z-50 bg-white bg-opacity-50">
          <div className={`w-36 h-36 z-20 animate-spin-slow`}>
            <PendingIcon className="fill-font-secondary w-36 h-36" />
          </div>
        </div>
      )}
      <>
        <div className="flex justify-between p-4">
          <span className="text-base font-medium text-font-primary">
            Confirm Your Order
          </span>
          <button
            className="text-base font-medium text-font-primary"
            onClick={onCancelContinue}
          >
            Cancel
          </button>
        </div>
        {!!errorMessage && (
          <div
            className="flex items-center bg-feedback-warning rounded-xl px-4 py-2 ml-4 mr-4 mb-4 mt-4"
            onClick={() => {
              dispatch(AppActions.order.initOrderStatus({}));
              Frames.init();
              setCardholder({
                name: "",
                billingAddress: ssoPayload?.address_line1
                  ? ssoPayload?.address_line1
                  : billingAddress.address_line1,
                country:
                  options.length && ssoPayload?.country
                    ? options.filter((op) => op.value === ssoPayload.country)[0]
                    : options.filter(
                        (op) => op.value === billingAddress.country
                      )[0],
                zipCode: ssoPayload?.zip ? ssoPayload?.zip : billingAddress.zip,
                city: ssoPayload?.city ? ssoPayload?.city : billingAddress.city,
              });
            }}
          >
            <CancelInCircleSvg />
            <p className="text-sm font-medium text-font-secondary ml-2">
              {errorMessage.message}
            </p>
          </div>
        )}
        <div className="flex flex-col h-full justify-between p-4 mt-4">
          <Frames
            config={{
              name: cardholder.name,
              cardholder: {
                name: cardholder.name,
                billingAddress: {
                  addressLine1: cardholder.billingAddress,
                  country: cardholder.country
                    ? cardholder.country.value
                    : options[0].value,
                  zip: cardholder.zipCode,
                  city: cardholder.city,
                },
              },
              debug: false,
              publicKey: process.env.REACT_APP_CHECKOUT_PUB_KEY!,
              modes: ["cvv_optional"],
              acceptedPaymentMethods: [
                "Visa",
                "Maestro",
                "Mastercard",
                // "American Express",
                // "Diners Club",
                // "Discover",
                // "JCB",
                // "Mada",
              ],
              localization: {
                cardNumberPlaceholder: "Card number",
                expiryMonthPlaceholder: "MM",
                expiryYearPlaceholder: "YY",
                cvvPlaceholder: "CVV",
              },
              style: {
                base: {
                  fontSize: "14px",
                },
              },
            }}
            ready={() => {
              setIsFormReady(true);
            }}
            frameActivated={(e) => {}}
            frameFocus={(e) => {}}
            frameBlur={(e) => {}}
            frameValidationChanged={(e) => {}}
            paymentMethodChanged={(e) => {
            }}
            cardValidationChanged={(e) => {}}
            cardSubmitted={() => {}}
            cardTokenized={(e) => {
              // onSubmitHandler(e)
              setCardTokenizedVal(e);
            }}
            cardTokenizationFailed={(e) => {
              alert("Tokenization Failed");
              setIsInProgress(false);
            }}
            cardBinChanged={(e) => {}}
          >
            <div className="checkout-form">
              <div className="m-2">
                <label>Card Number</label>
                <CardNumber />
              </div>
              <div className="date-and-code mt-4 m-2">
                <div className="mr-2">
                  <label>Expiry Date (MM/YY)</label>
                  <ExpiryDate />
                </div>
                <div>
                  <label>Card Security Code</label>
                  <Cvv />
                </div>
              </div>
              <Input
                label="Card Holder Name"
                name="name"
                placeholder="Card Holder Name"
                value={cardholder.name}
                onChange={onChangeCardHolderInfo}
                error={errors.name}
              ></Input>
              <Input
                label="Billing Address"
                name="billingAddress"
                placeholder="Billing Address"
                value={cardholder.billingAddress}
                onChange={onChangeCardHolderInfo}
                error={errors.billingAddress}
              ></Input>
              <DropDownForCheckout
                label="Country"
                name="country"
                placeholder="Please select the country"
                value={cardholder.country}
                options={options}
                onChange={onChangeCardHolderInfo}
              ></DropDownForCheckout>
              <Input
                label="City"
                name="city"
                placeholder="City"
                value={cardholder.city}
                onChange={onChangeCardHolderInfo}
                error={errors.city}
              ></Input>
              <Input
                label="Zip Code"
                name="zipCode"
                placeholder="Zip Code"
                value={cardholder.zipCode}
                onChange={onChangeCardHolderInfo}
                error={errors.zipCode}
              ></Input>
              <div className="w-[100%] flex items-center justify-center">
                <button
                  id="pay-button"
                  disabled={
                    !!errorMessage ||
                    !isFormReady ||
                    isInprogress ||
                    errors.billingAddress ||
                    errors.name ||
                    errors.zipCode ||
                    !isCardValid
                  }
                  onClick={() => {
                    if (validateFields()) {
                      setIsInProgress(true);
                      saveSsoPayload({
                        ...ssoPayload,
                        address_line1: cardholder.billingAddress,
                        city: cardholder.city,
                        country: cardholder.country.value,
                        zip: cardholder.zipCode,
                      } as ISsoPayload);
                      Frames.submitCard();
                    }
                  }}
                  className="m-2 mt-6 w-[100%]"
                >
                  PAY {order?.fiatAmount ?? amount} {currency ?? orderCurrency}
                </button>
              </div>
            </div>
          </Frames>
        </div>
      </>
    </>
  );
};

// @ts-ignore
import { PATHS } from "consts";
import { Button } from "components";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { PlatformContext } from "contexts";

interface IProps {
  //children: JSX.Element,
  displayImg?: string;
  displayMsg?: string;
  btnLabel?: string;
}

const VeriffDisplay: React.FC<IProps> = (props) => {

  // const { resetSession } = useContext(PlatformContext);
  const navigate = useNavigate();
  const { resetCookies } = useContext(PlatformContext);

  const onClickHome = () => {
    // resetSession();
    //destroyCookie(null, COOKIES.JWT);
    //onClose();
    // window.location.href = PATHS.CONFIRM_ORDER;
    resetCookies();
    navigate("/" + PATHS.HOME);
    window.parent.postMessage(
      {
        type: "order_cancelled",
      },
      "*"
    );
  };

  let { displayMsg, displayImg, btnLabel } = props;

  return (
    <div className="px-4 flex flex-col justify-center items-center">
      <div className="flex mt-4">
        <img src={displayImg} />
      </div>
      <div className="flex mt-4 items-center">
        <span className="text-base font-medium text-font-primary ml-4">
          {/* We are verifying your kyc status, please be patient. */}
          {displayMsg}
        </span>
      </div>
      <Button
        className="w-full mt-8"
        onClick={onClickHome}
      ><>{ btnLabel?btnLabel:'Cancel' }</></Button>
    </div>
  )

}

export default VeriffDisplay;


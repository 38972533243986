import React from "react";

interface IProps {
  key?: string,
  name?: string,
  type?: string,
  label?: string,
  value?: string,
  className?: string,
  error?: string,
  placeholder?: string,
  defaultValue?: string,
  disabled?: boolean,
  register?: any,
  onChange?: (value: string) => void,
}

const Input: React.FC<IProps> = (props) => {
  const { key, name, type, className, defaultValue, placeholder, value, label, disabled, register, onChange, error } = props;
  return (
    <>
      <div key={key} className={`relative px-4 pt-2 pb-2 bg-surface-secondary rounded-xl border ${!error ? 'border-surface-secondary' : 'border-feedback-warning'} focus-within:border-primary duration-200 ${className}`}>
        {
          disabled &&
          <div className="absolute left-0 top-0 w-full h-full opacity-60 bg-font-subtle rounded-xl"></div>
        }
        {
          label &&
          <label
            className={`left-4 text-xs font-medium text-font-subtle`}
          >
            {label}
          </label>
        }
        <input
          {...(name && register && register(name))}
          name={name}
          type={type}
          className={`block px-0 py-0 bg-transparent w-full text-base font-medium text-font-primary border-none focus:ring-0 focus:outline-none focus:border-none`}
          value={value}
          defaultValue={defaultValue}
          placeholder={placeholder}
          disabled={disabled}
          onChange={(e: any) => { onChange && onChange(e.target.value); }}
        />
      </div>
      {
        !!error && <label
          className='text-sm font-medium text-feedback-warning duration-200'
        >{error}</label>
      }
    </>
  );
};

export default Input;
// @ts-ignore
import { PlatformContext } from "contexts";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PATHS, USER_KYC_STATUS, VERIFF_DISPLAY } from "consts";
import { WarningSvg } from "assets";
import VeriffCongrats from "./VeriffCongrats";
import VeriffDisplay from "./VeriffDisplay";
import VeriffSession from "./VeriffSession";
import { useDispatch } from "react-redux";
import { AppActions } from "store";
import { IUser } from "types";

const VeriffAfterAuth: React.FC = () => {
  const dispatch = useDispatch();
  const [currentDisplay, setCurrentDisplay] = useState<VERIFF_DISPLAY>(
    VERIFF_DISPLAY.CHECK
  );
  const [currentVeriffUuid, setCurrentVeriffUuid] = useState<string>("");

  const navigate = useNavigate();

  const [currentUserKycStatus, setCurrentUserKycStatus] =
    useState<USER_KYC_STATUS>(USER_KYC_STATUS.NOT_TAKEN);

  const { user, saveToken, saveUser, setLogIn, ssoPayload, resetCookies } =
    useContext(PlatformContext);

  useEffect(() => {
    if (user) {
      setCurrentUserKycStatus(user.kycStatus);
    } else {
      setCurrentDisplay(VERIFF_DISPLAY.FAILURE);
    }
  }, []);

  useEffect(() => {
    //TODO
    if (currentDisplay == VERIFF_DISPLAY.APPROVED) {
      //dispatch(generateToken({ emailAddress: authInfo.emailAddress as string }));
      //      saveToken(COOKIES.JWT)
      navigate(PATHS.CONFIRM_ORDER);
    }
  }, [currentDisplay]);

  // useEffect(() => {
  //   if (generateTokenStatus === ACTION_STATUS.SUCCESS) {
  //     navigate(PATHS.BUY);
  //   }
  // }, [generateTokenStatus]);

  useEffect(() => {
    if (currentUserKycStatus == null) {
      setCurrentDisplay(VERIFF_DISPLAY.USER_NOT_FOUND);
    } else {
      if (
        currentUserKycStatus == USER_KYC_STATUS.NOT_TAKEN ||
        currentUserKycStatus == USER_KYC_STATUS.APPROVED
      ) {
        if (user && user.kycVeriffUuid) {
          setCurrentVeriffUuid(user.kycVeriffUuid);
          setCurrentDisplay(VERIFF_DISPLAY.SESSION);
        } else {
          setCurrentDisplay(VERIFF_DISPLAY.FAILURE);
        }
        return;
      }
      if (
        currentUserKycStatus == USER_KYC_STATUS.PENDING_BY_ID ||
        currentUserKycStatus == USER_KYC_STATUS.PENDING_BY_AML
      ) {
        setCurrentDisplay(VERIFF_DISPLAY.PENDING);
        return;
      }
      if (currentUserKycStatus == USER_KYC_STATUS.REJECTED) {
        setCurrentDisplay(VERIFF_DISPLAY.REJECTED);
        return;
      }
      if (currentUserKycStatus == USER_KYC_STATUS.APPROVED) {
        setCurrentDisplay(VERIFF_DISPLAY.APPROVED);
        //goto buy page
        return;
      }
      //setCurrentDisplay(VERIFF_DISPLAY.CHECK);
    }
  }, [currentUserKycStatus]);

  const nextStepOfVeriffSession = () => {
    navigate(PATHS.HOME);
    dispatch(
      AppActions.auth.ssoKyc({
        merchantId: ssoPayload?.merchant_id,
        userId: user?.id,
        saasUserCorporationId: 1,
        ssoCustomerId: ssoPayload?.sso_customer_id,
        ssoHash: ssoPayload?.sso_hash,
        next: (token: string, user: IUser) => {
          saveToken(token);
          saveUser(user);
          setLogIn(true);
          window.parent.postMessage(
            {
              type: "logged_in_success",
              token,
            },
            "*"
          );
        },
        error: (error: any) => {
          resetCookies();
          window.parent.postMessage(
            {
              type: "logged_in_failure",
              errorCode: 400,
            },
            "*"
          );
        },
      })
    );
    // setCurrentDisplay(VERIFF_DISPLAY.PENDING);
  };

  return (
    <>
      {
        {
          [VERIFF_DISPLAY.SESSION]: (
            <VeriffSession
              veriffUuid={currentVeriffUuid}
              nextStep={nextStepOfVeriffSession}
            />
          ),
          [VERIFF_DISPLAY.CHECK]: (
            <VeriffDisplay
              displayImg={WarningSvg}
              displayMsg="We are verifying your submission, please be patient."
            />
          ),
          [VERIFF_DISPLAY.PENDING]: (
            <VeriffDisplay
              displayImg={WarningSvg}
              displayMsg="Your submission is under review. You will be contacted shortly with a decision."
            />
          ),
          [VERIFF_DISPLAY.REJECTED]: (
            <VeriffDisplay
              displayImg={WarningSvg}
              displayMsg="Your kyc verification is rejected, please contact the support team."
            />
          ),
          [VERIFF_DISPLAY.APPROVED]: <></>,

          [VERIFF_DISPLAY.USER_NOT_FOUND]: (
            <VeriffDisplay
              displayImg={WarningSvg}
              displayMsg="Invited user not found, please contact the support team."
            />
          ),
          [VERIFF_DISPLAY.CONGRATS]: <VeriffCongrats />,
          [VERIFF_DISPLAY.FAILURE]: (
            <VeriffDisplay
              displayImg={WarningSvg}
              displayMsg="Unexpected technical issue, please contact the support team."
            />
          ),
        }[currentDisplay]
      }
    </>
  );
};

export default VeriffAfterAuth;

import { cryptoImages } from "assets";
import React from "react";

interface IProps {
  type?: string,
  label?: string,
  value?: string,
  className?: string,
  error?: string,
  placeholder?: string,
  crypto: string,
  onChange?: (value: string) => void,
}

const WalletAddressInput: React.FC<IProps> = (props) => {
  const {
    type,
    className,
    placeholder,
    value,
    label,
    onChange,
    error,
    crypto
  } = props;

  return (
    <div className={`relative ${className}`}>
      <label
        className={`absolute top-2 left-4 text-xs font-medium text-font-subtle`}
      >
        {label}
      </label>
      <input
        type={type}
        className={`block pl-4 pr-10 pt-6 pb-2 w-full bg-surface-secondary text-base font-medium text-font-primary rounded-xl border ${!error ? 'border-surface-secondary' : 'border-feedback-warning'} outline-none focus:ring-primary focus:border-primary duration-200`}
        defaultValue={value}
        placeholder={placeholder}
        onChange={(e: any) => { onChange && onChange(e.target.value); }}
      />
      <div className="absolute top-6 right-4 flex items-center">
        <img className="w-6 h-6" src={cryptoImages[crypto]} />
      </div>
      {!!error && <label
        className='text-sm font-medium text-feedback-warning duration-200'
      >{error}</label>}
    </div>
  );
};

export default WalletAddressInput;
export const Button: React.FC<React.ButtonHTMLAttributes<HTMLButtonElement>> = (props) => {
  const { className, children, ...restProps } = props;
  return (
    <button
      type="button"
      className={`p-4 text-base font-bold text-font-secondary rounded-full border bg-primary disabled:bg-feedback-disabled focus:bg-blue-600 duration-200 ${className}`}
      {...restProps}
    >
      {children}
    </button>
  );
};

export default Button;
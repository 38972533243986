import { call, put } from "redux-saga/effects";
import { AppActions } from "store";
import { ResponseGenerator } from "types";
import { lambdaServerApiClient } from "utils";

export function* getQuoteSaga(action: any) {
  try {
    const { next, ...payload } = action.payload;
    if (
      (action.payload.crypto === "UST" || action.payload.crypto === "USC") &&
      action.payload.currency === "USD"
    ) {
      yield put(
        AppActions.quote.getQuoteSuccess({
          liquidityPool: "b2c2",
          liquidityQuotes: "1",
        })
      );
    } else {
      const result: ResponseGenerator = yield call(
        async () =>
          await lambdaServerApiClient.post("/liquidity/quotes", payload)
      );
      yield put(AppActions.quote.getQuoteSuccess(result.data));
      if (next) next(result.data);
    }
  } catch (error: any) {
    if (action.payload.crypto === "UST" && action.payload.currency === "USD")
      yield put(
        AppActions.quote.getQuoteSuccess({
          liquidityPool: "b2c2",
          liquidityQuotes: "1",
        })
      );
    else yield put(AppActions.quote.getQuoteFailure(error.response.data));
    if (action.payload.next) action.payload.next(null);
  }
}

import { flagImages } from "assets";

const countryCurrency: {
  [key: string]: string,
} = {
  'AFN': 'AF',
  'ALL': 'AL',
  'ARS': 'AR',
  'AMD': 'AM',
  'ANG': 'CW',
  'AOA': 'AO',
  'AWG': 'AW',
  'DZD': 'DZ',
  'BHD': 'BH',
  'AUD': 'AU',
  'BDT': 'BD',
  'BYN': 'BY',
  'AZN': 'AZ',
  'BRL': 'BR',
  'BOB': 'BO',
  'BBD': 'BB',
  'BMD': 'BM',
  'BAM': 'BA',
  'BZD': 'BZ',
  'BND': 'BN',
  'BSD': 'BS',
  'BTN': 'BT',
  'GBP': 'GB',
  'BWP': 'BW',
  'BGN': 'BG',
  'KHR': 'KH',
  'CVE': 'CV',
  'BIF': 'BI',
  'CAD': 'CA',
  'CLP': 'CL',
  'COP': 'CO',
  'KMF': 'KM',
  'CNY': 'CN',
  'CDF': 'CD',
  'CRC': 'CR',
  'DJF': 'DJ',
  'EGP': 'EG',
  'HRK': 'HR',
  'CZK': 'CZ',
  'DOP': 'DO',
  'ERN': 'ER',
  'DKK': 'DK',
  'EEK': 'EE',
  'ETB': 'ET',
  'EUR': 'EU',
  'GEL': 'GE',
  'GHS': 'GH',
  'GTQ': 'GT',
  'GNF': 'GN',
  'HNL': 'HN',
  'HKD': 'HK',
  'ISK': 'IS',
  'HUF': 'HU',
  'INR': 'IN',
  'IDR': 'ID',
  'JMD': 'JM',
  'IRR': 'IR',
  'IQD': 'IQ',
  'JPY': 'JP',
  'JOD': 'JO',
  'KZT': 'KZ',
  'KES': 'KE',
  'ILS': 'IL',
  'KWD': 'KW',
  'LVL': 'LV',
  'LBP': 'LB',
  'LYD': 'LY',
  'LTL': 'LT',
  'MOP': 'MO',
  'MKD': 'MK',
  'MGA': 'MG',
  'MYR': 'MY',
  'MXN': 'MX',
  'MUR': 'MU',
  'MDL': 'MD',
  'MAD': 'MA',
  'MZN': 'MZ',
  'MMK': 'MM',
  'NPR': 'NP',
  'TWD': 'TW',
  'NZD': 'NZ',
  'NIO': 'NI',
  'NAD': 'NA',
  'NGN': 'NG',
  'NOK': 'NO',
  'OMR': 'OM',
  'PHP': 'PH',
  'PKR': 'PK',
  'PYG': 'PY',
  'QAR': 'QA',
  'PAB': 'PA',
  'PEN': 'PE',
  'RON': 'RO',
  'PLN': 'PL',
  'RUB': 'RU',
  'RSD': 'RS',
  'RWF': 'RW',
  'SAR': 'SA',
  'SGD': 'SG',
  'ZAR': 'ZA',
  'SOS': 'SO',
  'KRW': 'KR',
  'LKR': 'LK',
  'SDG': 'SD',
  'SEK': 'SE',
  'THB': 'TH',
  'CHF': 'CH',
  'SYP': 'SY',
  'TZS': 'TZ',
  'TOP': 'TO',
  'TTD': 'TT',
  'TND': 'TN',
  'TRY': 'TR',
  'USD': 'US',
  'UGX': 'UG',
  'UAH': 'UA',
  'AED': 'AE',
  'UYU': 'UY',
  'UZS': 'UZ',
  'VND': 'VN',
  'VEF': 'VE',
  'XOF': 'XO',
  'YER': 'YE',
  'ZMK': 'ZM',
  'ZWL': 'ZW',
  'FJD': 'FJ',
  'FKP': 'FK',
  'GIP': 'GI',
  'GYD': 'GY',
  'KGS': 'KG',
  'KYD': 'KY',
  'LAK': 'LA',
  'LRD': 'LR',
  'MNT': 'MN',
  'MRU': 'MR',
  'MVR': 'MV',
  'MWK': 'MW',
  'PGK': 'PG',
  'SBD': 'SB',
  'SCR': 'SC',
  'SHP': 'SH',
  'SLE': 'SL',
  'SLL': 'SL',
  'SRD': 'SR',
  'STN': 'ST',
  'SVC': 'SV',
  'SZL': 'SZ',
  'TJS': 'TJ',
  'TMT': 'TM',
  'VUV': 'VU',
  'XAF': 'CF',
  'XCD': 'AG',
  'XPF': 'PF',
  'ZMW': 'ZM',
};

export const getCurrencySvg = (currency: string) => {
  return flagImages[countryCurrency[currency]];
}

export const ibanCurrencies = ['AED', 'CHF', 'CZK', 'BGN', 'HUF', 'NOK', 'PLN', 'RON', 'SEK', 'TRY', 'HRK', 'DKK', 'EUR'];

export const bankAccountRules: {
  [key: string]: any;
} = {
  GBP: [
    {
      label: 'Sort Code',
      name: 'sortCode',
    }, {
      label: 'Account Number',
      name: 'accountNumber',
    },
  ],
  USD: [
    {
      label: 'Routing Number',
      name: 'routingNumber',
    }, {
      label: 'Account Number',
      name: 'accountNumber',
    },
  ],
  AUD: [
    {
      label: 'Account Number',
      name: 'accountNumber',
    }, {
      label: 'BSB Code',
      name: 'bsbCode',
    },
  ],
  INR: [
    {
      label: 'Account Number',
      name: 'accountNumber',
    }, {
      label: 'IFSC',
      name: 'ifsc',
    },
  ],
  MXN: [
    {
      label: 'Clabe',
      name: 'clabe',
    }, {
      label: 'BIC',
      name: 'bic',
    },
  ],
  IBAN_COUNTRY: [
    {
      label: 'IBAN',
      name: 'iban',
    }, {
      label: 'BIC',
      name: 'bic',
    },
  ],
  OTHER: [
    {
      label: 'Account Number',
      name: 'accountNumber',
    }, {
      label: 'BIC',
      name: 'bic',
    },
  ]
};

import { createSlice } from '@reduxjs/toolkit';
import { ICrypto } from 'types';

interface IInitialCryptoSlice {
  cryptos: ICrypto[],
  amount: number,
  gettingCryptos: boolean,
  gotCryptos: boolean,

  error: any,
}

const cryptoSlice = createSlice({
  name: 'quote',
  initialState: {
    cryptos: [],
    gettingCryptos: false,
    gotCryptos: false,

    amount: 0,
    error: '',
  } as IInitialCryptoSlice,
  reducers: {
    setAmount(state, action) {
      state.amount = action.payload;
    },

    getCryptos(state, action) {
      state.gettingCryptos = true;
      state.gotCryptos = false;
    },
    getCryptosSuccess(state, action) {
      state.gettingCryptos = false;
      state.gotCryptos = true;

      state.cryptos = [...action.payload];
    },
    getCryptosFailure(state, action) {
      state.gettingCryptos = false;
      state.gotCryptos = false;
      state.error = action.payload;
    },
  },
});

export const actions = cryptoSlice.actions;
export const reducer = cryptoSlice.reducer;
import React, { useEffect } from "react";

export const PaymentSuccessPage: React.FC = () => {

  useEffect(() => {
    window.parent.postMessage(
      {
        type: "order_completed",
      },
      "*"
    );
  }, []);

  return <></>;
};

export * from './icons';

declare const require: {
  context(
    directory: string,
    useSubdirectories: boolean,
    regExp: RegExp
  ): any;
};

function importAllCryptos(r: any) {
  try {
    let images = {} as any;
    r.keys().map((item: any) => { 
      images[item.replace('cryptos/', '').replace('./', '').replace('.svg', '').split(' ')[0].toUpperCase()] = r(item); 
    });
    return images;
  } catch (error) {
    console.error('Error importing crypto images:', error);
    return {};
  }
}

export const cryptoImages = importAllCryptos(require.context('./cryptos', false, /\.(svg)$/));

function importAllFlags(r: any) {
  let images = {} as any;
  r.keys().map((item: any) => { images[item.replace('flags/', '').replace('./', '').replace('.svg', '').split(' ')[0].toUpperCase()] = r(item); });
  return images;
}

export const flagImages = importAllFlags(require.context('./flags', false, /\.(svg)$/));
export * from './bank';

export enum USER_KYC_STATUS {
  NOT_TAKEN = 0,
  APPROVED = 1,
  PENDING_BY_ID = 2,
  PENDING_BY_AML = 3,
  REJECTED = 4,
}

export enum VERIFF_DISPLAY {
  CHECK = "CHECK",
  PENDING = "PENDING",
  REJECTED = "REJECTED",
  SESSION = "SESSION",
  USER_NOT_FOUND = "USER_NOT_FOUND",
  APPROVED = "APPROVED",
  CONGRATS = "CONGRATS",
  FAILURE = "FAILURE",
}

export const COOKIES = {
  token: "token",
  user: "user",
};

export const PATHS = {
  HOME: "home",
  CONFIRM_ORDER: "confirm-order",
  CARD_INFO: "/card-info",
  PAYMENT_SUCCESS: "/payment-success",
  CRYPTO_DEPOSIT: "/crypto-deposit",
  KYC: "kyc",
  WALLET_ADDRESS: "/wallet-address",
  ADD_BANK: "/add-bank",
  BILLING_ADDRESS: "/billing-address",
};

export const saasUserCorporationId = 1;

export enum RAMPING {
  ON = "buy",
  OFF = "sell",
}

export const termsOfServiceLink =
  "https://www.wynpay.io/terms-and-conditions.html";

export enum TransactionTypeForFee {
  OTC_ON_RAMP = 1,
  OTC_OFF_RAMP,
  RETAIL_ON_RAMP,
  RETAIL_OFF_RAMP,
  RETAIL_NFT_CHECKOUT,
}

export const privacyPolicyLink = "https://www.wynpay.io/privacy.html";

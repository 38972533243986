import { bankAccountRules, ibanCurrencies } from "consts";
import { IFeeIncludeOptions, IQuote } from "types";

export const getCrypto = (
  amount: number,
  quote: IQuote,
  fee: number,
  type: IFeeIncludeOptions
) => {
  if (amount === 0) return "0.00000";

  if (type === IFeeIncludeOptions.EXCLUDE) {
    return Math.max(+amount / +quote.liquidityQuotes, 0).toFixed(5);
  } else if (type === IFeeIncludeOptions.INCLUDE) {
    return Math.max((+amount - +fee) / +quote.liquidityQuotes, 0).toFixed(5);
  } else if (type === IFeeIncludeOptions.MERCHANT) {
    return Math.max(+amount / +quote.liquidityQuotes, 0).toFixed(5);
  } else {
    return "0.00000";
  }
};

export const getFiat = (
  amount: number,
  quote: IQuote,
  fee: number,
  type: IFeeIncludeOptions
) => {
  if (amount === 0) return "0.00";

  if (type === IFeeIncludeOptions.EXCLUDE) {
    return Math.max(+amount * +quote.liquidityQuotes, 0).toFixed(2);
  } else if (type === IFeeIncludeOptions.INCLUDE) {
    return Math.max(+amount * +quote.liquidityQuotes - +fee, 0).toFixed(2);
  } else if (type === IFeeIncludeOptions.MERCHANT) {
    return Math.max(+amount * +quote.liquidityQuotes, 0).toFixed(2);
  } else {
    return "0.00";
  }
};

export const ellipsisAddress = (address: string) => {
  return `${address.slice(0, 6)}...${address.slice(
    address.length - 4,
    address.length
  )}`;
};

export const getBankInputFields = (currency: string) => {
  if (ibanCurrencies.includes(currency)) {
    return bankAccountRules.IBAN_COUNTRY;
  }
  if (Object.keys(bankAccountRules).includes(currency))
    return bankAccountRules[currency];
  return bankAccountRules.OTHER;
};

export const validateBankInputFields = (accountDetail: any, currency: string) => {
  return getBankInputFields(currency).every(({ name }: { name: string }) => !!accountDetail[name]);
};

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Order } from "types";

type StateType = {
  authStatus: false;
  loading: boolean;
  order: Order | null;
  currency: string;
  amount: number;
  orderUuid: string;
  internalOrderCreating: boolean;
  billingAddress: any;
  error: any;
};

const initialState: StateType = {
  authStatus: false,
  loading: false,
  order: null,
  currency: "",
  amount: 0,
  orderUuid: "",
  internalOrderCreating: false,
  error: "",
  billingAddress: {},
};

const orderSlice = createSlice({
  name: "order",
  initialState: initialState,
  reducers: {
    initOrderStatus(state, action) {
      state.error = "";
    },
    createErrorOrderStatus(state, action) {
      state.error = action.payload;
    },
    /**
     * order
     */
    createSsoOrder(state, action: PayloadAction<any>) {
      state.internalOrderCreating = true;
    },
    createSsoOrderSuccess(state, action: PayloadAction<any>) {
      state.internalOrderCreating = false;
      state.order = action.payload.order;
    },
    createSsoOrderFailure(state, action: PayloadAction<any>) {
      state.internalOrderCreating = false;
    },

    createPaymentCheckout(state, action) {
      state.loading = true;
    },

    createPaymentCheckoutSuccess(state, action) {
      state.loading = false;
      if (action.payload.data.error) {
        state.error = { message: action.payload.data.error };
      }
    },

    createPaymentCheckoutFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },

    getPaymentDetail(state, action) {
      state.loading = true;
    },

    getPaymentCheckoutDetailSuccess(state, action) {
      state.loading = false;
      if (!action.payload.approved) {
        state.error = {
          message: action.payload.comment,
        };
        state.currency = action.payload.currency;
        state.amount = action.payload.amount;
        state.orderUuid = action.payload.reference;
        state.billingAddress = action.payload.source.billing_address;
      }
    },

    getPaymentCheckoutDetailFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },

    getCounterpartyId(state, action) {
      state.loading = true;
    },
    getCounterpartyIdSuccess(state, action) {
      state.loading = false;
    },
    getCounterpartyIdFailure(state, action) {
      state.loading = false;
    },
  },
});

export const actions = orderSlice.actions;
export const reducer = orderSlice.reducer;

import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { CardInfoPage, ConfirmOrderPage, PaymentSuccessPage } from "pages";
import { AppActions, RootState } from "store";
import { PlatformContext } from "contexts";
import { PATHS, RAMPING, saasUserCorporationId } from "consts";
import { IUser, OrderType } from "types";
import "./App.css";
import { VeriffAfterAuth } from "pages/veriff";
import { RoundDown2Digits } from "utils";
import EnterWalletAddress from "pages/EnterWalletAddress";
import BillingAddress from "pages/BillingAddress";
import AddBank from "pages/AddBank";
import PendingIcon from "assets/icons/PendingIcon";
import { validateBankInputFields } from "utils/supports";
import { Button } from "components";
import CryptoDeposit from "pages/CryptoDeposit";

function App() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const location = useLocation();

  const {
    isLogIn,
    ssoPayload,
    user,
    setLogIn,
    saveSsoPayload,
    saveToken,
    saveUser,
    resetCookies,
  } = useContext(PlatformContext);

  const [isCardValid, setIsCardValid] = useState(true);
  const { isLoading } = useSelector((root: RootState) => root.auth);
  const { cryptos } = useSelector((root: RootState) => root.crypto);
  const [errorMessage, setErrorMessage] = useState('');

  const params = new URLSearchParams(window.location.search);

  const login = (data: any, liquidityQuotes?: number) => {
    const {
      order_type: orderType,
      merchant_id: merchantId,
      sso_customer_id: ssoCustomerId,
      sso_hash: ssoHash,
      email_address: emailAddress,
      customer_first_name: firstName,
      customer_surname: lastName,
      dob,
      ip_address: ipAddress,
      order_amount: orderAmount,
    } = data;

    let amount;
    if (orderType === OrderType.OFF_RAMP) {
      if (!liquidityQuotes) {
        dispatch(
          AppActions.order.createErrorOrderStatus({
            message:
              "We cannot get liquidity quotes. Please try again or contact the Support team",
          })
        );
        return;
      } else {
        amount = +orderAmount * +liquidityQuotes;
      }
    } else {
      amount = RoundDown2Digits(orderAmount);
    }

    dispatch(
      AppActions.auth.ssoAuth({
        merchantId,
        ssoCustomerId,
        ssoHash,
        emailAddress,
        firstName,
        lastName,
        dob,
        saasUserCorporationId: 1,
        ipAddress,
        orderAmount: amount,
        next: (token: string, user: IUser) => {
          saveUser(user);
          saveToken(token);

          setLogIn(true);
          window.parent.postMessage(
            {
              type: "logged_in_success",
              token,
            },
            "*"
          );
        },
        kycRequired: (user: IUser) => {
          saveUser(user);
          navigate(PATHS.KYC);
          window.parent.postMessage(
            {
              type: "kyc_required",
            },
            "*"
          );
        },
        error: (error: any) => {
          resetCookies();

          window.parent.postMessage(
            {
              type: "logged_in_failure",
              errorCode: 400,
            },
            "*"
          );
        },
      })
    );
  }

  const handleSsoLogin = (data: any) => {
    saveSsoPayload({
      ...data,
      includeFee: +data.include,
    });

    if (data.order_type === OrderType.ON_RAMP) {
      login(data);
    } else {
      const ticker = cryptos.find((el) => el.shortName === data.crypto_currency)?.ticker;
      dispatch(AppActions.quote.getQuote({
        side: data.order_type ? RAMPING.OFF : RAMPING.ON,
        quantity: 1,
        crypto: ticker ?? data.crypto_currency,
        currency: data.currency,
        next: (quote: any) => {
          login(data, +quote.liquidityQuotes);
        },
      }));
    }
  }

  const messageListener = (event: MessageEvent<any>) => {
    const { data } = event;

    console.log("Message received from the parent: " + JSON.stringify(data));

    const { type } = data;

    if (!type) {
      return;
    }

    if (type === "sso_login") {
      handleSsoLogin(data);
    }

    if (type === "cko-msg") {
      if (data.event === "cardValidationChanged") {
        setIsCardValid(data.data.isValid);
      } else if (data.event === "paymentMethodChanged") {
        setIsCardValid(data.data.isPaymentMethodAccepted);
        if (!data.data.isPaymentMethodAccepted && data.data.paymentMethod) {
          dispatch(
            AppActions.order.createErrorOrderStatus({
              message:
                "We don't support this card type. Please use another card or contact our support team.",
            })
          );
        }
      }
    }
  };

  useEffect(() => {
    window.parent.postMessage({ type: "page_loaded" }, "*");
    window.addEventListener("message", messageListener);

    const ssoData: any = {};
    params.forEach((value, key) => {
      ssoData[key] = value;
    });
    console.log('ssoData: ', ssoData);
    if (ssoData.order_type !== undefined) {
      handleSsoLogin(ssoData);
    }

    return () => {
      window.removeEventListener("message", messageListener);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!ssoPayload) return;

    const {
      order_type: orderType,
    } = ssoPayload;
    if (+orderType === OrderType.ON_RAMP || +orderType === OrderType.NFT_ON_RAMP) {
      if (
        location.pathname.includes(PATHS.CARD_INFO) ||
        location.pathname.includes(PATHS.PAYMENT_SUCCESS)
      ) {
      } else if (isLogIn) {
        if (!location.pathname.includes(PATHS.CARD_INFO))
          navigate(PATHS.CONFIRM_ORDER);
      }
    }
  }, [isLogIn, ssoPayload, location.pathname, navigate]);

  useEffect(() => {
    if (!ssoPayload || !user) return;

    const {
      order_type: orderType,
      wallet_address: walletAddress,
      currency,
      sort_code: sortCode,
      account_number: accountNumber,
      routing_number: routingNumber,
      bsb_code: bsbCode,
      address_line1: streetLine1,
      city,
      country,
      zip: postcode,
      bank_country: bankCountry,
      ...rest
    } = ssoPayload;
    const accountDetail = {
      sortCode,
      accountNumber,
      routingNumber,
      bsbCode,
      ...rest,
    };

    if (isLogIn && +orderType === OrderType.OFF_RAMP) {
      if (!walletAddress) {
        navigate(PATHS.WALLET_ADDRESS);
        return;
      }
      const isValid = validateBankInputFields(accountDetail, currency);
      if (!isValid) {
        navigate(PATHS.ADD_BANK);
        return;
      }
      if (!streetLine1 || !city || !country || !postcode) {
        navigate(PATHS.BILLING_ADDRESS);
        return;
      }

      const {
        id: userId,
        firstName,
        lastName,
      } = user;

      dispatch(AppActions.order.getCounterpartyId({
        next: (data: any) => {
          const { httpsStatus, accountId, message } = data;
          if (!!accountId || httpsStatus === 422) {
            navigate(PATHS.CONFIRM_ORDER);
          }
          else
            setErrorMessage(message);
        },
        error: (error: any) => {
          console.log('error: ', error);
          setErrorMessage('It has not been possible to place your Order at this time. Please try again or contact the Support team');
        },
        userId: userId,
        issuerCountry: bankCountry,
        currency,
        individualName: {
          firstName,
          lastName,
        },
        ...{
          ...accountDetail,
          accountNo: accountNumber,
        },
        address: {
          streetLine1,
          city,
          country,
          postcode,
        }
      }));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogIn, ssoPayload, user]);

  useEffect(() => {
    dispatch(
      AppActions.crypto.getCryptos({
        saasUserCorporationId: saasUserCorporationId,
      })
    );
    dispatch(
      AppActions.currency.getCurrencies({
        saasUserCorporationId: saasUserCorporationId,
      })
    );
    dispatch(
      AppActions.country.getCountry({
        saasUserCorporationId: saasUserCorporationId,
      })
    )
  }, [dispatch]);

  const onCancelContinue = () => {
    window.parent.postMessage(
      {
        type: "order_cancelled",
      },
      "*"
    );
    setErrorMessage("");
  };

  return (
    <div className="app flex flex-col h-screen">
      {isLoading && (
        <div className="fixed w-full h-full flex justify-center items-center">
          <div className={`w-36 h-36 z-20 animate-spin-slow`}>
            <PendingIcon className="fill-font-secondary w-36 h-36" />
          </div>
        </div>
      )}
      {errorMessage && (
        <div className="fixed w-full h-full flex items-center justify-center">
          <div className="fixed -z-50 w-full h-full bg-black opacity-60"></div>
          <div className="z-100 flex flex-col items-center bg-white p-8 mx-4 border border-1 rounded-xl">
            <p className="text-md font-medium text-font-primary text-center">
              {errorMessage}
            </p>
            <Button className="h-12 py-1 px-4 mt-4" onClick={onCancelContinue}>
              Cancel
            </Button>
          </div>
        </div>
      )}
      <Routes>
        <Route path={PATHS.CONFIRM_ORDER} element={<ConfirmOrderPage />} />
        <Route
          path={PATHS.CARD_INFO + "/*"}
          element={<CardInfoPage isCardValid={isCardValid} />}
        />
        <Route
          path={PATHS.WALLET_ADDRESS + "/*"}
          element={<EnterWalletAddress crypto={ssoPayload?.crypto_currency as string} />}
        />
        <Route
          path={PATHS.ADD_BANK}
          element={<AddBank />}
        />
        <Route
          path={PATHS.BILLING_ADDRESS}
          element={<BillingAddress />}
        />
        <Route
          path={PATHS.PAYMENT_SUCCESS + "/*"}
          element={<PaymentSuccessPage />}
        />
        <Route
          path={PATHS.CRYPTO_DEPOSIT}
          element={<CryptoDeposit />}
        />
        <Route path={PATHS.KYC} element={<VeriffAfterAuth />} />
        <Route path="*" element={<div></div>} />
      </Routes>
    </div>
  );
}

export default App;

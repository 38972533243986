import { ReactComponent as ArrowDownSvg } from 'assets/icons/arrow-down.svg';

interface IProps {
  text: any;
  label?: string,
  className?: string,
  svgPath?: any,
  subText?: string,
  flag?: boolean,
  onClick?: () => void,
  disabled?: boolean
}

const SelectButton: React.FC<IProps> = (props) => {
  const { svgPath, className, label, text, subText, flag, disabled, onClick } = props;

  return (
    <div className={`relative ${className}`}>
      {
        disabled &&
        <div className="absolute w-full h-full opacity-60 bg-font-subtle rounded-xl"></div>
      }
      {
        label &&
        <label className="absolute top-4 left-4 text-xs font-medium text-gray-500">{label}</label>
      }
      <button
        type='button'
        className="flex justify-between items-center w-full px-4 pt-8 pb-4 text-base font-medium text-font-primary rounded-xl border bg-surface-secondary border-surface-secondary hover:bg-surface-secondary duration-200"
        onClick={() => { onClick && onClick(); }}
        disabled={disabled}
      >
        <div className="flex items-center">
          {
            svgPath && (
              flag ?
                <img className="w-8 h-6 mr-2" src={svgPath} /> :
                <div className="w-5 h-5 rounded-full bg-center bg-cover mr-2" style={{ backgroundImage: `url(${svgPath})` }} />
            )
          }
          {!!subText && <span className="text-base font-bold text-font-primary mr-1">{subText}</span>}<span className="text-base font-medium text-font-primary">{text}</span>
        </div>
        <ArrowDownSvg className='left-1' width={24} height={24}/>
      </button>
    </div>
  );
};

export default SelectButton;
import { AppActions } from "store";

import { call, put } from "redux-saga/effects";

import { publicApiClient } from "utils";
import { ResponseGenerator } from "types";

export function* ssoAuthSaga(action: any) {
  try {
    const { next, error, kycRequired, ...payload } = action.payload;
    const result: ResponseGenerator = yield call(
      async () => await publicApiClient.post("/login/sso-login", payload)
    );

    if (result) {
      const { token, user } = result.data;
      if (next && token && user) {
        next(token, user);
      }
      if (result.status === 205 && user) {
        action.payload.kycRequired(user);
      }
      yield put(AppActions.auth.ssoAuthSuccess(user));
    }
  } catch (error: any) {
    if (action.payload.error) {
      action.payload.error(error);
    }
    yield put(AppActions.auth.ssoAuthFailure(error));
  }
}

export function* ssoKycSaga(action: any) {
  try {
    const { next, error, ...payload } = action.payload;
    const result: ResponseGenerator = yield call(
      async () => await publicApiClient.post("/login/sso-kyc", payload)
    );

    if (result) {
      const { token, user } = result.data;
      if (token && next) {
        next(token, user);
        yield put(AppActions.auth.ssoAuthSuccess(user));
      }
    }
  } catch (error) {
    console.log("error:", error);
    if (action.payload.error) {
      action.payload.error(error);
    }
    yield put(AppActions.auth.ssoAuthFailure(error));
  }
}

import { useContext, useMemo, useState } from "react";
import { PlatformContext } from "contexts";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { getBankInputFields, validateBankInputFields } from "utils/supports";
import { ReactComponent as CancelInCircleSvg } from 'assets/icons/cancel-in-circle.svg';
import { Button } from "components";
import { flagImages } from "assets";
import Input from "components/common/Input";
import SelectButton from "components/common/SelectButton";
import SelectCountryPopup from "components/SelectCountryPopup";

const AddBank: React.FC = () => {
  const { ssoPayload, saveSsoPayload, user } = useContext(PlatformContext);
  const [accountDetail, setAccountDetail] = useState<any>({
    sortCode: ssoPayload?.sort_code,
    accountNumber: ssoPayload?.account_number,
    routingNumber: ssoPayload?.routing_number,
    bsbCode: ssoPayload?.bsb_code,
    clabe: ssoPayload?.clabe,
    ifsc: ssoPayload?.ifsc,
    iban: ssoPayload?.iban,
    bic: ssoPayload?.bic,
  });
  const [openCountrySelect, setOpenCountrySelect] = useState<boolean>(false);
  const { countries } = useSelector((root: RootState) => root.country);
  const [bankCountry, setBankCountry] = useState<string>(ssoPayload?.bank_country ?? countries[0]?.code);
  const [errorMessage, setErrorMessage] = useState('');
  const onChangeAccountDetail = (key: string, value: string) => {
    const temp = { ...accountDetail };
    temp[key] = value;
    setAccountDetail({ ...temp });
  };

  const isValid = useMemo(() => {
    if (!ssoPayload) return false;
    return validateBankInputFields(accountDetail, ssoPayload.currency); 
  }, [accountDetail, ssoPayload]);

  const onClickContinue = async () => {
    if (!ssoPayload || !user) return;

    const { sortCode, accountNumber, routingNumber, bsbCode, ...rest } = accountDetail;

    saveSsoPayload({
      ...ssoPayload,
      bank_country: bankCountry,
      sort_code: sortCode,
      account_number: accountNumber,
      routing_number: routingNumber,
      bsb_code: bsbCode,
      ...rest,
    });
  };

  const onCloseWarning = () => {
    setErrorMessage('');
  };

  const onCancel = () => {
    window.parent.postMessage(
      {
        type: "order_cancelled",
      },
      "*"
    );
    setErrorMessage("");
  };

  return (
    <>
      <div className="flex justify-between p-4">
        <span className="text-base font-medium text-font-primary">
          Enter Bank Details
        </span>
        <button
          className="text-base font-medium text-font-primary"
          onClick={onCancel}
        >
          Cancel
        </button>
      </div>
      <div className="flex flex-col h-full p-4 mt-4 justify-between">
        <div className="flex flex-col">
          {
            !!errorMessage &&
            <div className="flex items-center bg-feedback-warning rounded-xl px-4 py-2 mb-4" onClick={onCloseWarning}>
              <CancelInCircleSvg />
              <p className="text-sm font-medium text-font-secondary ml-2">{errorMessage}</p>
            </div>
          }
          <h1 className="text-2xl font-bold text-font-primary mb-4">Enter {ssoPayload?.currency} Bank Account Detail</h1>
          <div>
            <SelectButton
              className="mb-2"
              label="Bank Country"
              text={countries.find(el => el.code === bankCountry)?.name}
              svgPath={flagImages[bankCountry]}
              onClick={() => setOpenCountrySelect(true)}
              flag
            />
            {
              ssoPayload && getBankInputFields(ssoPayload.currency).map(({ label, name }: any) => (
                <Input
                  key={name}
                  className="mb-2"
                  label={label}
                  defaultValue={''}
                  onChange={value => onChangeAccountDetail(name, value)}
                />
              ))
            }
          </div>
        </div>
        <div
          className="w-full px-2"
        >
          <Button
            className="w-full"
            disabled={!isValid}
            onClick={onClickContinue}
          >
            Continue
          </Button>
        </div>
      </div>
      {
        openCountrySelect &&
        <SelectCountryPopup
          country={bankCountry}
          onChange={(value) => {
            setBankCountry(value);
            setOpenCountrySelect(true);
          }}
          onClose={
            () => setOpenCountrySelect(false)
          }
        />
      }
    </>
  );
};

export default AddBank;
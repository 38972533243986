import { USER_KYC_STATUS } from "consts";

export interface IUser {
  id: number;
  customerId?: number;
  kycStatus: USER_KYC_STATUS;
  kycVeriffUuid: string;
  role?: number;
  type?: number;
  createdAt?: string;
  activated?: number;
  email?: string;
  countryCode?: string;
  phoneNumber?: string;
  avatar?: string;
  firstName?: string;
  lastName?: string;
  birthday?: string;
}

export interface IQuote {
  liquidityProvider: string;
  liquidityQuotes: number;
}

export enum IFeeIncludeOptions {
  EXCLUDE,
  INCLUDE,
  MERCHANT
}

export interface IFee {
  fee: number;
}

export interface ICurrency {
  code: string;
  symbol: string;
  id: number;
}

export interface ICrypto {
  id: number;
  name: string;
  shortName: string;
  ticker: string;
  chain: string;
}

export interface ResponseGenerator {
  config?: any;
  data?: any;
  headers?: any;
  request?: any;
  status?: number;
  statusText?: string;
}

export interface ISsoPayload {
  merchant_id: string;
  sso_customer_id: string;
  sso_hash: string;
  email_address: string;
  customer_first_name: string;
  customer_surname: string;
  dob: string;
  ip_address: string;
  order_type: number;
  wallet_address: string;
  sso_order_id: string;
  order_amount: string;
  currency: string;
  crypto_currency: string;
  success_url: string;
  cancel_url: string;
  country: string;
  city: string;
  address_line1: string;
  zip: string;
  includeFee: IFeeIncludeOptions;
  nft_name?: string;

  bank_country: string;
  account_number: string;
  routing_number: string;
  sort_code: string;
  bsb_code: string;
  ifsc: string;
  bic: string;
  clabe: string;
  iban: string;

  support_email?: string;
}

export enum OrderType {
  ON_RAMP,
  OFF_RAMP,
  NFT_ON_RAMP
}

export enum OrderStatus {
  CREATED,
  AML_START,
  AML_HOLD,
  AML_REJECT,
  WAITING_FOR_DEPOSIT,
  PROCESSING,
  DEPOSIT_DECLINED,
  CANCEL_ORDER,
  FULLFILLED,
  DONE,
}

export enum TravelRuleStatus {
  CREATED,
  PENDING,
  APPROVED,
  REJECTED,
}

export enum AmlStatus {
  CREATED,
  PENDING,
  APPROVED,
  REJECTED,
}

export enum OtcStatus {
  RetailerOrder,
  OTCOrder,
}

export interface Order {
  id: number;
  customerId: number;
  merchantId: number;
  type: OrderType;
  customerWalletId: number;
  currencyId: number;
  fiatAmount: number;
  cryptoId: number;
  cryptoAmount: number;
  liquidityQuote: number;
  liquidityProvider: string;
  processingFee: number;
  status: OrderStatus;
  reference: string | null;
  orderUuid: string | null;
  travelRuleId: string | null;
  travelRuleStatus: TravelRuleStatus;
  amlId: string | null;
  amlPayload: string | null;
  amlScore: number | null;
  amlStatus: AmlStatus;
  paymentIp: string;
  mid: string;
  remark: string | null;
  isOtc: OtcStatus;
  ssoOrderId: string;
  depositWalletAddress: string | null;
}

export interface ICountry {
  name: string;
  code: string;
  fullName?: string;
  phoneCode?: string;
  language?: string;
  flag?: any;
}

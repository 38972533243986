import { useContext, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { PlatformContext } from "contexts";
import { ReactComponent as CancelInCircleSvg } from "assets/icons/cancel-in-circle.svg";
import Input from "components/common/Input";
import SelectButton from "components/common/SelectButton";
import { flagImages } from "assets";
import { Button } from "components";
import SelectCountryPopup from "components/SelectCountryPopup";
import { RootState } from "store";

const BillingAddress: React.FC = () => {
  const { ssoPayload, saveSsoPayload } = useContext(PlatformContext);
  const { countries } = useSelector((root: RootState) => root.country);
  const [openCountrySelect, setOpenCountrySelect] = useState<boolean>(false);
  const [addressDetail, setAddressDetail] = useState({
    country: ssoPayload?.country ?? 'AF',
    streetLine1: ssoPayload?.address_line1 ?? '',
    city: ssoPayload?.city ?? '',
    postcode: ssoPayload?.zip ?? '',
  });
  const [errorMessage, setErrorMessage] = useState('');

  const onChangeAddressDetail = (key: string, value: string) => {
    const temp: any = { ...addressDetail };
    temp[key] = value;
    setAddressDetail({ ...temp });
  }

  const isValid = useMemo(() => {
    return addressDetail.streetLine1 && addressDetail.city && addressDetail.country && addressDetail.postcode;
  }, [addressDetail]);

  const onClickContinue = async () => {
    if (!ssoPayload) return;

    saveSsoPayload({
      ...ssoPayload,
      country: addressDetail.country,
      city: addressDetail.city,
      address_line1: addressDetail.streetLine1,
      zip: addressDetail.postcode,
    });
  };

  const onCloseWarning = () => {
    setErrorMessage('');
  };

  const onCancel = () => {
    window.parent.postMessage(
      {
        type: "order_cancelled",
      },
      "*"
    );
    setErrorMessage("");
  };

  return (
    <>
      <div className="flex justify-between p-4">
        <span className="text-base font-medium text-font-primary">
          Enter Billing Address
        </span>
        <button
          className="text-base font-medium text-font-primary"
          onClick={onCancel}
        >
          Cancel
        </button>
      </div>
      <div className="flex flex-col h-full p-4 mt-4 justify-between">
        <div className="flex flex-col">
          {
            !!errorMessage &&
            <div className="flex items-center bg-feedback-warning rounded-xl px-4 py-2 mb-4" onClick={onCloseWarning}>
              <CancelInCircleSvg />
              <p className="text-sm font-medium text-font-secondary ml-2">{errorMessage}</p>
            </div>
          }
          <h1 className="text-2xl font-bold text-font-primary mt-4 mb-2">Enter Billing Address</h1>
          <div>
            <Input
              className="mb-2"
              label={'Street Line 1'}
              value={addressDetail.streetLine1 || ''}
              onChange={value => onChangeAddressDetail('streetLine1', value)}
            />
            <Input
              className="mb-2"
              label={'City'}
              value={addressDetail.city || ''}
              onChange={value => onChangeAddressDetail('city', value)}
            />
            <Input
              className="mb-2"
              label={'Postcode / Zipcode'}
              value={addressDetail.postcode || ''}
              onChange={value => onChangeAddressDetail('postcode', value)}
            />
            <SelectButton
              className="mb-2"
              label="Country"
              text={countries.find(el => el.code === addressDetail.country)?.name}
              svgPath={flagImages[addressDetail.country]}
              onClick={() => setOpenCountrySelect(true)}
              flag
            />
          </div>
        </div>
        <div
          className="w-full px-2"
        >
          <Button
            className="w-full"
            disabled={!isValid}
            onClick={onClickContinue}
          >
            Continue
          </Button>
        </div>
      </div>
      {
        openCountrySelect &&
        <SelectCountryPopup
          country={addressDetail.country}
          onChange={(value) => {
            onChangeAddressDetail('country', value);
            setOpenCountrySelect(true);
          }}
          onClose={
            () => setOpenCountrySelect(false)
          }
        />
      }
    </>
  );
};

export default BillingAddress;
import { ReactComponent as CancelSvg } from 'assets/icons/cancel.svg';
import { SelectCountry } from "./common/SelectCountry";

interface IProps {
  country: string;
  onChange: (country: string) => void;
  onClose: () => void;
}

const SelectCountryPopup: React.FC<IProps> = (props) => {
  const { country, onChange, onClose } = props;

  return (
    <div className="flex flex-col fixed left-0 top-0 w-full h-screen z-50 bg-surface-primary p-4">
      <div className="flex justify-between items-end mb-4">
        <label className="text-lg font-bold text-font-primary">Select a Country</label>
        <CancelSvg onClick={() => { onClose(); }} />
      </div>
      <SelectCountry
        country={country}
        onChange={(country) => {
          onChange(country);
          onClose();
        }}
      />
    </div>
  );
};

export default SelectCountryPopup;
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IUser } from "types";

type StateType = {
  authStatus: false,
  user: IUser | null,
  isLoading: boolean,
};

const initialState: StateType = {
  authStatus: false,
  user: null,
  isLoading: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    /**
     * auth
     */
    ssoAuth(state: any, action: PayloadAction<any>) {
      state.isLoading = true;
      state.authStatus = action.payload.authStatus;
    },
    ssoAuthSuccess(state: any, action: PayloadAction<any>) {
      state.isLoading = false;
      state.user = { ...action.payload };
    },
    ssoAuthFailure(state: any, action: PayloadAction<any>) {
      state.isLoading = false;
    },

    /**
     * kyc
     */
    ssoKyc(state: any, action: PayloadAction<any>) {
      state.isLoading = true;
    },
    ssoKycSuccess(state: any, action: PayloadAction<any>) {
      state.isLoading = false;
      state.user = { ...action.payload };
    },
    ssoKycFailure(state: any, action: PayloadAction<any>) {
      state.isLoading = false;
    },
  },
});

export const actions = authSlice.actions;
export const reducer = authSlice.reducer;


import { call, put } from 'redux-saga/effects';
import { AppActions } from 'store';
import { ResponseGenerator } from 'types';
import { customerServerApiClient } from 'utils';

export function* getCountriesSaga(action: any) {
  try {
    const { saasUserCorporationId } = action.payload;
    const result: ResponseGenerator = yield call(
      async () => await customerServerApiClient.get('country/supported', {
        params: {
          saasUserCorporationId: saasUserCorporationId
        }
      })
    );
    const { countries } = result.data;
    yield put(AppActions.country.getCountrySuccess(countries));
  } catch (error: any) {
    yield put(AppActions.country.getCountryFailure(error.response.data));
  }
}
import { customerServerApiClient, lambdaServerApiClient, lcOffRampApiClient, publicApiClient } from "utils";
import { AppActions } from "store";

import { call, put } from "redux-saga/effects";
import { OrderType } from "types";

interface ResponseGenerator {
  config?: any;
  data?: any;
  headers?: any;
  request?: any;
  status?: number;
  statusText?: string;
}

export function* createSsoOrderSaga(action: any) {
  try {
    const { next, error, ...payload } = action.payload;
    const result: ResponseGenerator = yield call(
      async () =>
        payload.type === OrderType.OFF_RAMP ? await lcOffRampApiClient.post("/order/create-sso-order", payload) : await publicApiClient.post("/order/create-sso-order", payload)
    );

    const orderData = result.data;
    yield put(AppActions.order.createSsoOrderSuccess(orderData));

    if (result) {
      if (next) {
        next(orderData.order);
      }
    }
  } catch (error: any) {
    if (action.payload.error) {
      action.payload.error(error.response.data.error);
    }

    yield put(AppActions.order.createSsoOrderFailure(error));
  }
}

export function* createPaymentCheckoutSaga(action: any) {
  try {
    const result: ResponseGenerator = yield call(
      async () =>
        await customerServerApiClient.post(
          "/acquirer/checkout/create-payment",
          action.payload
        )
    );

    yield put(AppActions.order.createPaymentCheckoutSuccess(result.data));

    // if (action.payload.next && action.payload.nextFailure) {
    if (result.data.data.error && action.payload.nextFailure)
      action.payload.nextFailure(result.data.data.redirectUrl);
    else
      action.payload.next && action.payload.next(result.data.data.redirectUrl);
    // }
  } catch (error: any) {
    yield put(
      AppActions.order.createPaymentCheckoutFailure(error.response.data)
    );
    if (action.payload.nextFailure) {
      action.payload.nextFailure(error.response.data.redirectUrl);
    }
  }
}

export function* getPaymentCheckoutDetailSaga(action: any) {
  try {
    const result: ResponseGenerator = yield call(
      async () =>
        await customerServerApiClient.get(
          `/acquirer/checkout/${action.payload.sessionId}`
        )
    );

    yield put(AppActions.order.getPaymentCheckoutDetailSuccess(result.data));

    if (action.payload.next) {
      action.payload.next(result.data.data.redirectUrl);
    }
  } catch (error: any) {
    if (error.response)
      yield put(
        AppActions.order.getPaymentCheckoutDetailFailure(error.response.data)
      );
    yield put(
      AppActions.order.getPaymentCheckoutDetailFailure(error)
    );
  }
}

export function* getCounterpartyIdSaga(action: any) {
  try {
    const { next, error, ...payload } = action.payload;
    const result: ResponseGenerator = yield call(
      async () =>
        await lambdaServerApiClient.post('/apm/get-counterparty-id', payload)
    );

    if (next) {
      next(result.data);
    }

    yield put(AppActions.order.getCounterpartyIdSuccess({}));
  } catch (error: any) {
    yield put(AppActions.order.getCounterpartyIdFailure(error.response.data));
    if (action.payload.error) {
      action.payload.error(error.response.data);
    }
  }
}

import React from "react";

enum SIZE {
  sm = "sm",
  md = "md",
  lg = "lg",
}

interface IProps {
  key?: string;
  name?: string;
  type?: string;
  label?: string;
  value?: string;
  className?: string;
  size?: SIZE;
  error?: string;
  placeholder?: string;
  defaultValue?: string;
  disabled?: boolean;
  onChange?: (value: any) => void;
}

const InputForCheckout: React.FC<IProps> = (props) => {
  const {
    key,
    name,
    type,
    className,
    defaultValue,
    placeholder,
    value,
    label,
    disabled,
    onChange,
    size = SIZE.lg,
    error,
  } = props;
  return (
    <>
      <div
        key={key}
        className={`m-2 pt-2 pb-2 rounded-xl ${
          !error ? "border-surface-secondary" : "border-feedback-warning"
        } focus-within:border-primary duration-200 ${className}`}
      >
        {disabled && (
          <div className="absolute left-0 top-0 w-full h-full opacity-60 bg-font-subtle rounded-xl"></div>
        )}
        {label && (
          <label className={`left-4 text-xs font-medium text-font-subtle`}>
            {label}
          </label>
        )}
        <input
          name={name}
          type={type}
          className={`block px-0 w-full text-base font-medium text-font-primary placeholder-checkout-input-border font-weight-200 placeholder-opacity-{0.5} pl-[40px] text-[14px] py-[7px] border rounded-sm border-checkout-input-border focus:ring-0 focus:border-[1px]`}
          value={value}
          defaultValue={defaultValue}
          placeholder={placeholder}
          disabled={disabled}
          onChange={(e: any) => {
            onChange && onChange(e);
          }}
        />
      </div>
      {!!error && (
        <div className="ml-2 pb-2 text-sm font-medium text-feedback-warning duration-200">
          {error}
        </div>
      )}
    </>
  );
};

export default InputForCheckout;

import { useContext, useEffect, useState } from "react";
import { validate } from "crypto-address-validator-ts";

import WalletAddressInput from "components/common/WalletAddressInput";
import { Button } from "components";
import { PlatformContext } from "contexts";

interface EnterWalletAddressProps {
  crypto: string;
};

const EnterWalletAddress: React.FC<EnterWalletAddressProps> = ({
  crypto
}) => {
  const [walletAddress, setWalletAddress] = useState<string>('');
  const [error, setError] = useState<string>('');
  const { ssoPayload, saveSsoPayload } = useContext(PlatformContext);

  const onChangeWalletAddress = (value: string) => {
    setWalletAddress(value);
  }

  useEffect(() => {
    let valid = false;
    if (walletAddress) {
      valid = validate(walletAddress, (crypto ?? 'USDT').toLowerCase(), {
        networkType: '',
        chainType: '',
      });
    }

    if (!valid && walletAddress) {
      setError('Wallet Address is not correct.');
    } else {
      setError('');
    }
  }, [crypto, walletAddress]);

  const onClickContinue = () => {
    if (ssoPayload) {      
      saveSsoPayload({
        ...ssoPayload,
        wallet_address: walletAddress,
      });
    }
  }

  const onCancel = () => {
    window.parent.postMessage(
      {
        type: "order_cancelled",
      },
      "*"
    );
  };

  return (
    <>
      <div className="flex justify-between p-4">
        <span className="text-base font-medium text-font-primary">
          Enter Wallet Address
        </span>
        <button
          className="text-base font-medium text-font-primary"
          onClick={onCancel}
        >
          Cancel
        </button>
      </div>
      <div className="flex flex-col h-full justify-between p-4 mt-4">
        <div className="flex flex-col">
          <label className="text-2xl font-bold text-font-primary">Enter {crypto} Wallet Address</label>
          <div className="mt-4">
            <WalletAddressInput
              label={`${crypto} Wallet Address`}
              crypto={crypto}
              onChange={onChangeWalletAddress}
              error={error}
            />
          </div>
        </div>
        <div
          className="w-full px-2"
        >
          <Button
            className="w-full"
            disabled={!!error || !walletAddress}
            onClick={onClickContinue}
          >
            Continue
          </Button>
          <div className="bottem-6 flex flex-col items-center pr-4 pl-4 w-full">
            <p className="text-center text-xs font-medium text-font-primary mt-2">By continuing you confirm that you own and have control of this wallet.</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default EnterWalletAddress;
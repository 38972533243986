import { ArrowDownSvg, ArrowUpSvg } from "assets";
import React, { useState } from "react";

interface OptionType {
  value: string;
  label: string;
}

enum SIZE {
  sm = "sm",
  md = "md",
  lg = "lg",
}

interface IProps {
  key?: string;
  name?: string;
  options?: OptionType[];
  label?: string;
  value?: OptionType;
  className?: string;
  size?: SIZE;
  error?: string;
  placeholder?: string;
  defaultValue?: string;
  disabled?: boolean;
  onChange?: (value: any) => void;
}

export const DropDownForCheckout: React.FC<IProps> = (props) => {
  const {
    key,
    name,
    options,
    className,
    defaultValue,
    placeholder,
    value,
    label,
    disabled,
    onChange,
    size = SIZE.lg,
    error,
  } = props;

  const [isDropDownOpened, setIsDropDownOpened] = useState(false);

  return (
    <>
      <div
        key={key}
        className={`relative m-2 pt-2 pb-2 rounded-xl ${
          !error ? "border-surface-secondary" : "border-feedback-warning"
        } focus-within:border-primary duration-200 ${className}`}
      >
        {disabled && (
          <div className="absolute left-0 top-0 w-full h-full opacity-60 bg-font-subtle rounded-xl"></div>
        )}
        {label && (
          <label className={`left-4 text-xs font-medium text-font-subtle`}>
            {label}
          </label>
        )}
        <div
          className={`px-0 w-full overflow-y flex items-center text-base font-medium text-font-primary placeholder-checkout-input-border font-weight-200 placeholder-opacity-{0.5} pl-[40px] text-[14px] py-[7px] border rounded-sm border-checkout-input-border focus:ring-0 focus:border-[1px] relative`}
          onClick={() => setIsDropDownOpened(!isDropDownOpened)}
        >
          {value?.label || placeholder}
          {isDropDownOpened ? (
            <img className="h-[14px] w-[50px] ml-auto" src={ArrowUpSvg} />
          ) : (
            <img className="h-[14px] w-[50px] ml-auto" src={ArrowDownSvg} />
          )}
        </div>
        <div
          className={`h-[250px] overflow-y-scroll bg-white px-0 w-full overflow-y text-base font-medium text-font-primary placeholder-checkout-input-border font-weight-200 placeholder-opacity-{0.5} pl-[40px] text-[14px] py-[7px] border rounded-sm border-checkout-input-border focus:ring-0 focus:border-[1px] absolute z-999 ${
            !isDropDownOpened && "hidden"
          }`}
        >
          {options?.map((op) => (
            <div
              className="hover:bg-[#13395e] hover:text-white"
              onClick={() => {
                setIsDropDownOpened(false);
                onChange && onChange({ target: { name: name, value: op } });
              }}
              key={op.label}
            >
              {op.label}
            </div>
          ))}
        </div>
      </div>
      {!!error && (
        <label className="text-sm font-medium text-feedback-warning duration-200">
          {error}
        </label>
      )}
    </>
  );
};

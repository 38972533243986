import { call, put } from 'redux-saga/effects';
import { AppActions } from 'store';
import { ResponseGenerator } from 'types';
import { customerServerApiClient } from 'utils';

export function* getCryptosSaga(action: any) {
  try {
    const { saasUserCorporationId } = action.payload;
    const result: ResponseGenerator = yield call(
      async () => await customerServerApiClient.get('/crypto/supported', {
        params: {
          saasUserCorporationId: saasUserCorporationId
        }
      })
    );
    const { cryptos } = result.data;
    yield put(AppActions.crypto.getCryptosSuccess(cryptos));
  } catch (error: any) {
    yield put(AppActions.crypto.getCryptosFailure(error.response.data));
  }
}
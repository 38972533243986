interface IProps {
  className?: string;
  width?: number;
  height?: number;
  fill?: string;
  onClick?: () => void;
}

const PendingIcon: React.FC<IProps> = (props) => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect x="17" y="2.5" width="2" height="5" fill="#3BB861" />
      <rect
        x="22.8503"
        y="3.29395"
        width="2"
        height="5"
        transform="rotate(21.5 22.8503 3.29395)"
        fill="#3BB861"
      />
      <rect
        x="28.05"
        y="6.26953"
        width="2"
        height="5"
        transform="rotate(43 28.05 6.26953)"
        fill="#3BB861"
      />
      <rect
        x="31.88"
        y="11.0605"
        width="2"
        height="5"
        transform="rotate(66.5 31.88 11.0605)"
        fill="#3BB861"
      />
      <rect
        x="28.3"
        y="19"
        width="2"
        height="5"
        transform="rotate(-90 28.3 19)"
        fill="#3BB861"
      />
      <rect
        x="2.5"
        y="19"
        width="2"
        height="5"
        transform="rotate(-90 2.5 19)"
        fill="#3BB861"
      />
      <rect
        x="3.29419"
        y="13.0498"
        width="2"
        height="5"
        transform="rotate(-68.5 3.29419 13.0498)"
        fill="#3BB861"
      />
      <rect
        x="6.21997"
        y="7.90039"
        width="2"
        height="5"
        transform="rotate(-47 6.21997 7.90039)"
        fill="#3BB861"
      />
      <rect
        x="11.04"
        y="4.13965"
        width="2"
        height="5"
        transform="rotate(-23.5 11.04 4.13965)"
        fill="#3BB861"
      />
      <rect
        width="2"
        height="5"
        transform="matrix(1 0 0 -1 17 33.3877)"
        fill="#3BB861"
      />
      <rect
        width="2"
        height="5"
        transform="matrix(0.930417 -0.366501 -0.366501 -0.930417 22.8401 32.6299)"
        fill="#3BB861"
      />
      <rect
        width="2"
        height="5"
        transform="matrix(0.731354 -0.681998 -0.681998 -0.731354 28.11 29.7197)"
        fill="#3BB861"
      />
      <rect
        width="2"
        height="5"
        transform="matrix(0.398749 -0.91706 -0.91706 -0.398749 31.8799 24.9102)"
        fill="#3BB861"
      />
      <rect
        width="2"
        height="5"
        transform="matrix(0.366501 0.930417 0.930417 -0.366501 3.29004 22.9404)"
        fill="#3BB861"
      />
      <rect
        width="2"
        height="5"
        transform="matrix(0.681998 0.731354 0.731354 -0.681998 6.21997 28.04)"
        fill="#3BB861"
      />
      <rect
        width="2"
        height="5"
        transform="matrix(0.91706 0.398749 0.398749 -0.91706 11.04 31.748)"
        fill="#3BB861"
      />
    </svg>
  );
};

export default PendingIcon;

// @ts-ignore
import { useNavigate } from "react-router-dom";
import { PATHS } from "consts";
import { CongratsSvg } from "assets";
import { Button } from "components";

const VeriffCongrats: React.FC = () => {
  const navigate = useNavigate();

  const onClickHome = () => {
    navigate(PATHS.HOME);
  };

  return (
    <div className="flex flex-col justify-center p-4">
      <div className="flex flex-col items-center text-center mb-4">
        <img src={CongratsSvg} className="w-16 h-16" />
        <br />
        <span className="text-base font-medium text-font-primary mt-4">
          Congratulations!
        </span>
      </div>
      <div className="flex flex-col item-center text-center mb-4">
        {/* <img src={CongratsSvg} /> */}
        <span className="text-base font-medium text-font-primary">
          Your account has been activated. You can now login to our platform.
        </span>
      </div>
      <Button className="w-full" onClick={onClickHome}>
        <>Proceed to Login</>
      </Button>
    </div>
  );
};

export default VeriffCongrats;
